import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  FormGroup,
  Form,
  Input,
  label,
  Spinner,
  FormFeedback,
} from "reactstrap";
import { Link, useRouteMatch, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useState, useEffect } from "react";
import classnames from "classnames";
import ApiService from "../services/APIService";

const Settings = ({}) => {
  const [state, setState] = useState({
    activeTab: "1",
  });
  const [settings, setSettings] = useState({});
  const [adminEmail, setAdminEmail] = useState("");
  const [adminCommission, setAdminCommission] = useState("");
  const [cancellationFee, SetCancellationFee] = useState("");
  const [currentPassword, SetCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, SetConfirmPassword] = useState("");
  const [uid, setUid] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [validation, setValidation] = useState({
    admin_email: undefined,
    admin_commission: undefined,
    cancellation_fee: undefined,
    current_password: undefined,
    new_password: undefined,
    confirm_password: undefined,
  });
  const isValidateForm = () => {
    let errors = {};
    if (!adminEmail) errors.admin_email = "Please enter admin email";
    if (!adminCommission)
      errors.admin_commission = "Please enter valid commission";
    if (!cancellationFee) errors.cancellation_fee = "Please enter valid fee";
    setValidation({ ...errors });
    return Object.keys(errors).length === 0;
  };

  const isValidateFormPassword = () => {
    let errors = {};
    if (!currentPassword)
      errors.current_password = "Please enter current password";
    if (!newPassword) errors.new_password = "Please enter new password";
    if (!confirmPassword)
      errors.confirm_password = "Please enter the confirm password";
    if (!!currentPassword && currentPassword.length < 5)
      errors.current_password = "Current Password must be minimum 6 characters";
    if (!!newPassword && newPassword.length < 5)
      errors.new_password = "New Password must be minimum 6 characters";
    if (!!confirmPassword && confirmPassword.length < 5)
      errors.confirm_password = "Confirm Password must be minimum 6 characters";
    if (
      !!confirmPassword &&
      confirmPassword.length >= 6 &&
      !!newPassword &&
      newPassword != confirmPassword
    )
      errors.confirm_password = "Confirm Password must be same as new password";
    setValidation({ ...errors });
    return Object.keys(errors).length === 0;
  };
  useEffect(() => {
    getSettings();
    getDetails();
  }, []);

  const getSettings = async () => {
    try {
      const API = new ApiService();
      const { data } = await API._GET({
        api: `/user/settings`,
        params: {},
      });
      setAdminEmail(data.admin_email);
      setAdminCommission(data.admin_commission);
      SetCancellationFee(data.cancellation_fee);
    } catch (error) {
      addToast(error.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const { addToast } = useToasts();

  const submit = async () => {
    if (!isValidateForm()) {
      return;
    }
    const API = new ApiService();
    setLoading(true);
    let body = [
      { name: "admin_commission", value: adminCommission },
      { name: "cancellation_fee", value: cancellationFee },
      { name: "admin_email", value: adminEmail },
    ];
    API._PUT({
      api: "/user/settings/bulk",
      body: {
        items: body,
      },
    })
      .then(({ data }) => {
        addToast("Updated", {
          appearance: "success",
          autoDismiss: true,
        });
        setLoading(false);
        console.log(data);
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const changePassword = async () => {
    if (!isValidateFormPassword()) {
      return;
    }
    const API = new ApiService();
    setLoading(true);
    API._POST({
      api: "/auth/password/change",
      body: {
        uid,
        current_password: currentPassword,
        new_password: newPassword,
      },
    })
      .then(() => {
        addToast("Password changed successfully", {
          appearance: "success",
          autoDismiss: true,
        });
        setNewPassword("");
        SetCurrentPassword("");
        SetConfirmPassword("");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const getDetails = async () => {
    const API = new ApiService();
    API._GET({
      api: "/user/me",
      params: {},
    })
      .then(({ data }) => {
        setUid(data.user.uid);
      })
      .catch((error) => {
        addToast(error.data ? error.data.message : "Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const tabToggle = (tab) => {
    if (state.activeTab !=tab) {
      setState({ activeTab: tab });
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              <CardFooter>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: state.activeTab=== "1",
                      })}
                      onClick={() => {
                        tabToggle("1");
                      }}
                    >
                      Account
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: state.activeTab=== "2",
                      })}
                      onClick={() => {
                        tabToggle("2");
                      }}
                    >
                      Payment
                    </NavLink>
                  </NavItem>
                </Nav>
                <hr />

                <TabContent activeTab={state.activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col COL="12" lg="6">
                        <h5 className="mt-4 mb-1 text-left py-2">
                          <b>Change Password</b>
                        </h5>

                        <FormGroup className="text-left mt-5 pb-3">
                          <div>
                            <label>CURRENT PASSWORD</label>
                            <Input
                              placeholder="Current Password"
                              type="password"
                              value={currentPassword}
                              valid={
                                !validation.current_password && currentPassword
                              }
                              invalid={!!validation.current_password}
                              onChange={({ target: { value } }) => {
                                SetCurrentPassword(value);
                                isValidateFormPassword();
                              }}
                            />
                            <FormFeedback>
                              {validation.current_password}
                            </FormFeedback>
                          </div>
                          <br />
                          <div>
                            <label>NEW PASSWORD</label>
                            <Input
                              placeholder="New Password"
                              type="password"
                              value={newPassword}
                              valid={!validation.new_password && newPassword}
                              invalid={!!validation.new_password}
                              onChange={({ target: { value } }) => {
                                setNewPassword(value);
                                isValidateFormPassword();
                              }}
                            />
                            <FormFeedback>
                              {validation.new_password}
                            </FormFeedback>
                          </div>
                          <br />
                          <div>
                            <label>CONFIRM PASSWORD</label>
                            <Input
                              placeholder="Confirm Password"
                              type="password"
                              value={confirmPassword}
                              valid={
                                !validation.confirm_password && confirmPassword
                              }
                              invalid={!!validation.confirm_password}
                              onChange={({ target: { value } }) => {
                                SetConfirmPassword(value);
                                value && isValidateFormPassword();
                              }}
                            />
                            <FormFeedback>
                              {validation.confirm_password}
                            </FormFeedback>
                          </div>

                          <Button
                            className="btn-round  btn-md mt-4"
                            color="info"
                            type="button"
                            onClick={changePassword}
                          >
                            {isLoading && (
                              <Spinner
                                style={{ width: "1rem", height: "1rem" }}
                                // type="grow"
                                color="dark"
                              />
                            )}
                            Save Settings{" "}
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Col COL="12" lg="6">
                      <h5 className="mt-4 mb-1 text-left py-2">
                        <b>Payment Settings</b>
                      </h5>

                      <FormGroup className="text-left mt-5 pb-3">
                        <div>
                          <label>ADMIN EMAIL</label>
                          <Input
                            placeholder="Admin Email"
                            type="text"
                            value={adminEmail}
                            valid={!validation.admin_email && adminEmail}
                            invalid={!!validation.admin_email}
                            onChange={({ target: { value } }) => {
                              if (value.length <= 50) setAdminEmail(value);
                            }}
                          />
                          <FormFeedback>{validation.admin_email}</FormFeedback>
                        </div>
                        <br />
                        <div>
                          <label>ADMIN COMMISSION</label>
                          <Input
                            placeholder="Commission"
                            type="text"
                            value={adminCommission}
                            valid={
                              !validation.admin_commission && adminCommission
                            }
                            invalid={!!validation.admin_commission}
                            onChange={({ target: { value } }) => {
                              if (value <= 99) setAdminCommission(value);
                            }}
                          />
                          <FormFeedback>
                            {validation.admin_commission}
                          </FormFeedback>
                        </div>
                        <br />
                        <div>
                          <label>CANCELLATION FEE</label>
                          <Input
                            placeholder="Cancellation Fee"
                            type="text"
                            value={cancellationFee}
                            valid={
                              !validation.cancellation_fee && cancellationFee
                            }
                            invalid={!!validation.cancellation_fee}
                            onChange={({ target: { value } }) => {
                              if (value <= 99) SetCancellationFee(value);
                            }}
                          />
                          <FormFeedback>
                            {validation.cancellation_fee}
                          </FormFeedback>
                        </div>

                        <Button
                          className="btn-round  btn-md mt-4"
                          color="info"
                          type="button"
                          onClick={submit}
                        >
                          {isLoading && (
                            <Spinner
                              style={{ width: "1rem", height: "1rem" }}
                              // type="grow"
                              color="dark"
                            />
                          )}
                          Save Settings{" "}
                        </Button>
                      </FormGroup>
                    </Col>
                  </TabPane>
                </TabContent>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Settings;
