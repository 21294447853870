import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  label,
  Spinner,
  FormFeedback,
} from "reactstrap";
import { isNumber } from "../utils/helper";

const AddEditBoardCertifications = ({
  is_open,
  onClose,
  isLoading,
  onSubmit,
  data,
}) => {
  const [service, setService] = useState("");
  const [cpt_code, setCptCode] = useState("");
  const [cost, setCost] = useState("");
  const [validation, setValidation] = useState({
    service: undefined,
    cpt_code: undefined,
    cost: undefined,
  });
  const [processing, setSubmit] = useState(false);

  const isValidateForm = () => {
    let errors = {};
    if (!service) errors.service = "Please enter Certification Name";
    setValidation({ ...errors });
    return Object.keys(errors).length === 0;
  };

  const submit = () => {
    setSubmit(true);
    if (!isValidateForm()) {
      setSubmit(false);
      return;
    }
    onSubmit({ service, cpt_code, cost });
    setService("");
  };

  useEffect(() => {
    if (data) {
      setService(data.name);
      setCptCode(data.cpt);
      setCost(data.cost);
    }
  }, [data]);

  const toggle = () => onClose();

  return (
    <div>
      <Modal isOpen={is_open} toggle={toggle}>
        <ModalHeader toggle={toggle} className="modeltitle">
          <b>
            {data ? "Edit Board Certifications" : "Add Board Certifications"}
          </b>
        </ModalHeader>
        <ModalBody className="text-center pb-2">
          <FormGroup className="text-left pb-3">
            <div>
              <label>Board Certification Name</label>
              <Input
                placeholder="Certification"
                type="text"
                value={service}
                valid={!validation.service && !!service}
                invalid={!!validation.service}
                onChange={({ target: { value } }) => {
                  if (service.length > 0 && value.length <= 50) {
                    setService(value);
                  } else {
                    setService(value.replace(/^\s+|\s+$/gm, ""));
                  }
                  value && isValidateForm();
                }}
              />
              <FormFeedback>{validation.service}</FormFeedback>
            </div>
            <Button
              className="btn-round  btn-block mt-4"
              color="info"
              type="button"
              onClick={submit}
            >
              {isLoading && (
                <Spinner
                  style={{ width: "1rem", height: "1rem" }}
                  // type="grow"
                  color="dark"
                />
              )}
              {data ? "Save" : "Add"}
            </Button>
          </FormGroup>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddEditBoardCertifications;
